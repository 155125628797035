import React from 'react'
import facebook from '../../../assets/images/footer/facebook.svg'
import instagram from '../../../assets/images/footer/instagram.svg'
import logo from '../../../assets/images/footer/logo.webp'
import tiktok from '../../../assets/images/footer/tiktok.svg'
import './Footer.scss'

import email from '../../../assets/images/footer/email.svg'
import hours from '../../../assets/images/footer/hours.svg'
import location from '../../../assets/images/footer/location.svg'
import phone from '../../../assets/images/footer/phone.svg'
import { links } from "../../../constants/links"
import ClientLogoCarousel from '../ClientLogoCarousel/ClientLogoCarousel'


const Footer = () => {
  return (
    <div id='contato' className='Footer'>
      <div className="map">
        <iframe
          id={'map-frame'}
          title={"Visão do Google Maps"}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.8122519132176!2d-46.647294624182265!3d-23.503271059461547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef7c4c4600697%3A0xad39ba6a21d1cd63!2sR.%20Le%C3%A3o%20XIII%2C%20166%20-%20Jardim%20Sao%20Bento%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2002526-000!5e0!3m2!1sen!2sbr!4v1714504350929!5m2!1sen!2sbr"
          allowFullScreen="" loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <ClientLogoCarousel />
      <footer>
        <div className="left-footer">
          <div className="top-left">
            <img src={logo} alt="Logo midas" title="Logo midas" loading="lazy" />
            <div>
              <a href={links.contact.facebook} target='_blank' rel="noreferrer"><img src={facebook} alt="facebook" title="facebook" loading="lazy" /></a>
              <a href={links.contact.tiktok} target='_blank' rel="noreferrer"><img src={tiktok} alt="tiktok" title="tiktok" loading="lazy" /></a>
              <a href={links.contact.instagram} target='_blank' rel="noreferrer"><img src={instagram} alt="instagram" title="instagram" loading="lazy" /></a>
            </div>
          </div>
          <div className="bottom-left">
            <div>
              <img src={location} alt="Localização" title="Localização" loading="lazy" />
              <a href={links.map.google}>{links.map.address}</a>
            </div>
            <div>
              <img src={hours} alt="Horário" title="Horário" loading="lazy" />
              <a href={links.map.google}>{links.map.hours}</a>
            </div>
            <div>
              <img src={email} alt="Email" title="Email" loading="lazy" />
              <a href={links.mail.link}>{links.mail.verbose}</a>
            </div>
            <div>
              <img src={phone} alt="Telefone" title="Telefone" loading="lazy" />
              <a href={links.phone.link}>{links.phone.verbose}</a>
            </div>
          </div>
        </div>
        <div className="right-footer">
          <p>Links</p>
          <div>
            <a href='/'>Início</a>
            <a href='/#cursos'>Cursos</a>
            <a href='/#diretores'>Diretores Artísticos</a>
            <a href='/#historia'>História</a>
            <a href='/#store'>Midas Store</a>
            <a href='/#espaco'>Nosso espaço</a>
            <a href={links.contact.generic}>Contato</a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
