import React from 'react'
import './Space.scss'
import Slider from './Slider/Slider';
import doorImage from '../../../assets/images/space/door.png'
import Button from '../../common/Button/Button'
import {links} from "../../../constants/links";
import image1 from '../../../assets/images/slide/foto1.webp'
import image2 from '../../../assets/images/slide/foto2.webp'
import image3 from '../../../assets/images/slide/foto3.webp'
import image4 from '../../../assets/images/slide/foto4.webp'
import image5 from '../../../assets/images/slide/foto5.webp'
import image6 from '../../../assets/images/slide/foto6.webp'

const Space = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6
  ]
  return (
    <section id='espaco' className='Space'>
      <p className='title'>CONHEÇA NOSSO ESPAÇO</p>
      <div className="container-slide">
        <Slider images={images} />
      </div>
      <div className="info-space">
        <div>
          <img src={doorImage} alt="Imagem de porta" title="Imagem de porta" loading="lazy" />
          <p>Venha conhecer pessoalmente nosso espaço.</p>
        </div>
        <Button href={links.contact.bookTour}>MARCAR UMA VISITA</Button>
      </div>
    </section>
  )
}

export default Space
