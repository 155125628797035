import React from 'react';
import './NavigationLink.scss';

const NavigationLink = ({href, children, active, onClick}) => {
    return (
    <a onClick={onClick} className={`NavigationLink ${active ? 'active' : ''}`} href={href}>
        {children}
    </a>);
}

export default NavigationLink;
