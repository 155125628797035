import React, { useState } from 'react';
import './Slider.scss';
import arrowIcon from '../../../../assets/images/space/arrow.svg'

const Slider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="slider">
      <div className="prev" onClick={prevSlide}>
        <img
          src={images[currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1]}
          alt="Anterior"
          title="Anterior"
          loading="lazy"
        />
      </div>

      <button aria-label={"Slide Anterior"} className='prev-mobile' onClick={prevSlide}><img src={arrowIcon} alt="Flecha"  loading="lazy" /></button>
      <div className="slider-images">
        {images.map((image, index) => (
          <div
            style={{backgroundImage: `url(${image})`}}
            key={index}
            // src={image}
            // alt={`Imagem ${index}`}
            className={index === currentImageIndex ? 'active' : 'inactive'}
          />
        ))}
      </div>
      <button aria-label={"Próximo Slide"} className='next-mobile' onClick={nextSlide}><img src={arrowIcon} alt="Flecha" loading="lazy" /></button>
      <div className="next" onClick={nextSlide}>
        <img
          src={images[currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1]}
          alt="Próxima"
          title="Próxima"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Slider;
