import React from 'react'
import './History.scss'
import arrowIcon from '../../../assets/images/history/arrow.png'
import Button from '../../common/Button/Button'
import MasonryGrid from './MasonryGrid/MasonryGrid'
import { links } from "../../../constants/links";
import photo1484 from '../../../assets/images/history/photo-1484.webp'
import photo1493 from '../../../assets/images/history/photo-1493.webp'
import photo1547 from '../../../assets/images/history/photo-1547.webp'
import photo1619 from '../../../assets/images/history/photo-1619.webp'

const History = () => {

  const images = [
    photo1493,
    photo1619,
    photo1484,
    photo1547,
  ]
  return (
    <section id='historia' className='History'>
      <div className="title-history">
        <img src={arrowIcon} alt="Flecha" title="Flecha" loading="lazy" />
        <p>NOSSA HISTÓRIA</p>
        <img src={arrowIcon} alt="Flecha" title="Flecha" loading="lazy"/>
      </div>

      <div className="containers-history">
        <div className="left-history">
          <p>
            Fundada em 2024, a Midas Academy iniciou suas atividades na área de música, dança e teatro, a partir de um desejo dos fundadores de criar uma escola que pudesse desenvolver talentos e ajudá- los a se posicionar efetivamente no mercado artístico real.
            <br /><br />
            Com mais de 35 anos de experiência descobrindo e desenvolvendo artistas de sucesso como Charlie Brown Jr., Mamonas Assassinas, Titãs, Ira!, Vitor Kley, Manu Gavassi, entre outros, <strong>Rick Bonadio inaugura a Midas Academy, a escola ideal para quem busca a excelência artística.</strong>
            <br /><br />
            Com toda essa experiência na direção de novos talentos, desenvolvendo grandes produções e com excelência sonora, Rick Bonadio reuniu professores-artistas consagrados do mercado fonográfico e das artes para integrar nossa equipe.
            <br /><br />
            Juntos, criaram um método próprio para que todos os alunos-artistas tenham o conhecimento e preparo técnico totalmente alinhados com a sua carreira artística.
            <br /><br />
            É a realização de um sonho e também a forma mais eficaz de deixar um legado de anos dedicados à música e a arte de um dos maiores produtores musicais do país, Rick Bonadio.
          </p>
          <Button href={links.contact.joinHistory}>Quero fazer parte dessa história</Button>
        </div>
        <div className="right-history">
          <MasonryGrid columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
            {images.map((image, i) => (
              <img
                key={i}
                src={image}
                style={{ width: "100%", display: "block" }}
                alt={`Foto ${i + 1} da história da Midas Academy`}
                title={`Foto ${i + 1} da história da Midas Academy`}
                loading="lazy"
              />
            ))}
          </MasonryGrid>
        </div>
      </div>
    </section>
  )
}

export default History
