import React, { useEffect, useState } from 'react';
import { ReactComponent as Baby } from "../../../assets/images/courses/baby-button.svg";
import backgroundDescription from '../../../assets/images/courses/background-description.png';
import { ReactComponent as Dance } from "../../../assets/images/courses/dance-button.svg";
import { ReactComponent as Especial } from "../../../assets/images/courses/especial-button.svg";
import graphics from '../../../assets/images/courses/grafismo.svg';
import { ReactComponent as Music } from "../../../assets/images/courses/music-button.svg";
import background from '../../../assets/images/courses/Organic-background.png';
import { ReactComponent as Theater } from "../../../assets/images/courses/theater-button.svg";
import { relatedCourses } from '../../../constants/courses';
import CoursePopUp from './CoursePopUp/CoursePopUp';
import './Courses.scss';

const Courses = () => {
  const [categoryFilter, setCategoryFilter] = useState("Musicais");
  const [shownCourses, setShownCourses] = useState(relatedCourses);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    const courses = relatedCourses.filter((course) => {
      const hasCategory = categoryFilter === "" || course.category === categoryFilter;
      return hasCategory && course;
    })
    setShownCourses(courses)
  }, [categoryFilter])

  const toggleFilter = (filter, updateState, value) => {
    if (filter !== value) {
      updateState(value)
    }
    else {
      updateState("")
    }
  }

  const openPopup = (course) => {
    setIsOpen(true);
    setSelectedCourse(course);
  };

  const closePopup = () => {
    setIsOpen(false);
    setSelectedCourse(null);
  };

  return (
    <section id='cursos' className='Courses'>
      <div className="title-courses">
        <div>
          <p>CURSOS</p>
          <img src={graphics} alt="Gráfismo em pontos" title="Gráfismo em pontos" loading="lazy" />
        </div>
      </div>

      <div className="container-courses">
        <div className="buttons-course">
          <button aria-label={"Musicais"} className={`${categoryFilter === "Musicais" ? "active" : ""}`}
            onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "Musicais")}
          ><Music />Musicais</button>
          <button aria-label={"Especial"} className={`${categoryFilter === "Especial" ? "active" : ""}`}
            onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "Especial")}
          >
            <Especial />Especial
          </button>
          <button aria-label={"Dança"} className={`${categoryFilter === "Dança" ? "active" : ""}`}
            onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "Dança")}
          ><Dance />Dança</button>
          <button aria-label={"Teatro"} className={`${categoryFilter === "Teatro" ? "active" : ""}`}
            onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "Teatro")}
          ><Theater />Teatro</button>
          <button aria-label={"Babies"} className={`${categoryFilter === "Babies" ? "active" : ""}`}
            onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "Babies")}
          ><Baby />Babies</button>
        </div>

        <div className="container-course">
          <div className="distribution-course">
            <img className='background-section' src={background} alt="Background da seção" title="Background da seção" loading="lazy" />
            {shownCourses && shownCourses?.map((course, index) => (
              <div style={{ backgroundImage: `url(${course.image})` }} onClick={() => openPopup(course)} className={`course ${course.name === "DEMO TAPE" ? 'tape' : ''}`} key={index}>
                {/* <img src={course.image} alt={course.name} /> */}
                <div>
                  <span>{course.name}</span>
                  <p>{course.describe}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="description-container">
            <div className="description">

              {categoryFilter === "Musicais" ?
                <>
                  <div className="background">
                    <img src={backgroundDescription} alt="fundo colorido" title="fundo colorido" loading="lazy" />
                    <div>
                      <strong>AULAS</strong>
                      <strong>BÔNUS</strong>
                    </div>
                  </div>
                  <div className="description-text">
                    <span>PRÁTICA EM CONJUNTO</span>
                    <p>Duas aulas mensais, onde os alunos irão aos estúdios Midas, praticar com os nossos professores.
                    </p>
                  </div>
                  <div className="description-text">
                    <span>DIREÇÃO ARTÍSTICA</span>
                    <p>Uma aula mensal focada no direcionamento de carreira, conceito artístico, repertório, arranjos entre outros.
                    </p>
                  </div>
                  <div className="description-text">
                    <span>MUSICALIZAÇÃO</span>
                    <p>Uma aula mensal de teoria musical para todos os instrumentos.</p>
                  </div>
                </>
                : categoryFilter === "Especial" ?
                  <>
                    <div className="background">
                      <img src={backgroundDescription} alt="fundo colorido" title="fundo colorido" loading="lazy" />
                      <div>
                        <strong>AULAS</strong>
                        <strong>BÔNUS</strong>
                      </div>
                    </div>
                    <div className="description-text">
                      <span>PRÁTICA EM CONJUNTO</span>
                      <p>Duas aulas mensais, onde os alunos irão aos estúdios Midas, praticar com os nossos professores.
                      </p>
                    </div>
                    <div className="description-text">
                      <span>DIREÇÃO ARTÍSTICA</span>
                      <p>Uma aula mensal focada no direcionamento de carreira, conceito artístico, repertório, arranjos entre outros.
                      </p>
                    </div>
                    <div className="description-text">
                      <span>MUSICALIZAÇÃO</span>
                      <p>Uma aula mensal de teoria musical para todos os instrumentos.</p>
                    </div>
                  </>
                  :
                  <>
                  </>
              }

            </div>
          </div>
        </div>
      </div>
      <CoursePopUp isOpen={isOpen} onClose={closePopup} course={selectedCourse} />
    </section>
  )
}

export default Courses
