import React, { useState } from 'react'
import './Teachers.scss'
import icon from '../../../assets/images/teachers/icon-top.png'
import { teacher } from '../../../constants/teacher';
import Teacher from './Teacher/Teacher';

const Teachers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const openPopup = (course) => {
    setIsOpen(true);
    setSelectedCourse(course);
  };

  const closePopup = () => {
    setIsOpen(false);
    setSelectedCourse(null);
  };

  return (
    <section id='diretores' className='Teachers'>
      <div className="title-teacher">
        <img src={icon} alt="Icone de coroa" title="Icone de coroa" loading="lazy" />
        <p>DIRETORES ARTÍSTICOS</p>
      </div>

      <div className="container-teachers">
        {teacher.map((teacher, index) => (
          <div className='teacher' key={index}>
            <div className='image' style={{backgroundImage: `url(${teacher.image})` }} 
             alt={teacher.name} />
            <div>
              <span>{teacher.name} {teacher.twoName}</span>
              <div className='networks'>
                <button aria-label={"Ver mais sobre " + teacher.name} onClick={() => openPopup(teacher)}>Ver mais</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Teacher isOpen={isOpen} onClose={closePopup} course={selectedCourse} />
    </section>
  )
}

export default Teachers
