export const teacher = [
  {
    name: "Rick",
    twoName: "Bonadio",
    describe: "Harpa",
    image: require("../../src/assets/images/teachers/rick-bonadio.webp"),
    phone: "11432432432",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com",
    information: `<p>Ricardo Bonadio, 53 anos, é produtor, compositor, músico e empresário musical. </p>
    <p>Experiente profissional do mercado fonográfico brasileiro tem em seu currículo a
    descoberta de grandes nomes da música e a produção de artistas de expressiva
    vendagem como Mamonas Assassinas, Charlie Brown Jr, Tihuana, CPM22, Manu
    Gavassi, Titãs, Vitor Kley, Ultraje a Rigor, Planta & Raiz, Luiza Possi, Rouge, Br’oz,
    IRA!, NX Zero, Fresno, entre outros.</p>
    <p>Foi diretor geral da Virgin Records Brasil no período de janeiro de 1997 até abril de
    2001, sendo responsável pelas áreas: Artística / Marketing Nacional e Marketing
    Internacional, onde dirigiu o desenvolvimento, no Brasil, de artistas como
    Backstreet Boys, Spice Girls, Britney Spears, Manu Chao, Lenny Kravitz, The Rolling
    Stones.</p>
    <p>Participou como jurado das duas edições do programa Popstars ( SBT ), sendo o
    produtor do projeto e dos discos das bandas selecionadas: “Rouge” (2002) e
    “Br’oz” (2003). Também comandou o quadro “Olha a minha banda” no Caldeirão
    do Huck na TV Globo, e criou o primeiro reality show dentro de um estúdio de
    gravação: o Fábrica de Estrelas, que foi ao ar pelo Multishow em 2013.</p>
    <p>Além de comandar um dos maiores estúdios e gravadora da América Latina, o
    Midas Estúdios, Rick inaugurou no início de 2024 a Midas Academy, escola de
    música e artes com a missão de trazer a vivência real do mercado para que os
    alunos-artistas estejam 100% preparados para começar ou alavancar a sua
    carreira.</p>`
  },
  {
    name: "Fernando",
    twoName: "Prado",
    describe: "Harpa",
    image: require("../../src/assets/images/teachers/fernando.webp"),
    phone: "11432432432",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com",
    information: `<p>Fernando Prado, ao lado de Rick Bonadio, tem se destacado como um dos principais responsáveis pelo sucesso de diversos artistas na indústria fonográfica brasileira, proporcionando um som único e de alta qualidade.</p>
    <p>Entre suas colaborações de destaque estão: Vitor Kley, Luiza Possi, Sergio Brito, Kell Smith e Manu Gavassi. Sua parceria com Rick Bonadio tem sido essencial para o sucesso de vários projetos musicais.</p>
    <p>Com um olhar atento e uma paixão pela música, Fernando Prado continua a surpreender e encantar o público com seu trabalho, assegurando que cada projeto seja único e memorável.</p>
    `
  },
  {
    name: "Paulo",
    twoName: "Guilherme | PG",
    describe: "Harpa",
    image: require("../../src/assets/images/teachers/pg.webp"),
    phone: "11432432432",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com",
    information: `<p>Baterista e sócio/co-fundador das bandas Ostheobaldo, Tihuana, Urbana Legion e fundador do Sacramento.
    </p> <p>Começou a tocar bateria aos 16 anos, no Rio de Janeiro, sua cidade natal. 
    Teve como principais influências grandes bateristas do rock: Neil Peart, Stewart Copeland, John Boham, Alex Van Halen, Buddy Miles, Chad Smith, Cozy Powell, João Barone e Larry Mullen Jr.</p> <p>Em uma carreira de quase 20 anos, o Tihuana lançou sete álbuns, com disco de ouro no álbum de Estreia Ilegal, turnês internacionais, passando pelo Japão e EUA. Uma de suas músicas, Tropa de Elite, foi tema e deu nome ao filme de maior sucesso no cinema nacional.</p> <p>Em 2019, PG iniciou um novo projeto com 2 amigos, a banda Sacramento. Inicialmente o objetivo era fazer releituras das músicas preferidas dos integrantes da banda, porém com a pandemia, o projeto se expandiu para composições autorais que foram lançadas ao longo de 2022 e 2023.</p>`
  },
  {
    name: "Paula",
    twoName: "Bonadio",
    describe: "Harpa",
    image: require("../../src/assets/images/teachers/paula.webp"),
    phone: "11432432432",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com",
    information: `<p>Formada pelo Centro de Dança Rio, com direção de Mariza Estrela, estudou ballet clássico, jazz, sapateado, dança contemporânea, danças folclóricas, história e anatomia da dança.
    </p> 
    <p>Integrou o elenco dos shows da Disney "100 anos de Magia", em 2005 e "Sonho e Fantasia", em 2006.</p>
    <p>Atuou como bailarina assistente de coreografia na novela "Dance Dance Dance" da Rede Bandeirantes ( TV BAND ).<br/>
    Em 2011, foi assistente de coreografia do "Miss Universo". Também foi coreógrafa dos shows da Banda Rebeldes da Rede Record e coreógrafa e diretora de palco dos concursos de "Miss Brasil" e "Miss Regionais", de 2014 a 2019.</p> 
    <p>Atualmente trabalha na preparação corporal e performática dos artistas contratados da Gravadora Midas Music.</p>`
  },
  {
    name: "Fernando de ",
    twoName: "Gino",
    describe: "Harpa",
    image: require("../../src/assets/images/teachers/gino.webp"),
    phone: "11432432432",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com",
    information: `<p>Com uma paixão precoce pelo piano, Fernando de Gino começou a explorar o instrumento aos 11 anos, marcando sua estreia como pianista em eventos locais já aos 12 anos de idade. Seus primeiros passos na teoria e técnicas de piano foram orientados pelo professor Sebastian Junior, marcando o início de uma jornada musical multifacetada.</p>
    <p>Atualmente, Fernando De Gino atua como pianista, arranjador e produtor na gravadora Midas Music, colaborando diretamente com o produtor Rick Bonadio. </p>
    <p>Seu talento também o levou a desempenhar o papel de diretor musical e tecladista do grupo Inimigos da HP de 2015 a 2019. Além disso, foi responsável pela produção musical do quadro "Caixa de Talentos" no programa "Agora é com Datena".</p>
    <p>Com uma carreira diversificada e repleta de realizações, Fernando De Gino continua a ser uma força dinâmica na cena musical brasileira, inspirando outros com sua paixão pela música e dedicação ao seu trabalho. </p>`
  },
]