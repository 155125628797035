import { BrowserRouter } from 'react-router-dom';
import './App.css';
import WhatsAppButton from './common/WhatsAppButton/WhatsAppButton';
import Biography from './pages/Biography/Biography';
import MainCarrousel from './pages/carrousel/MainCarrousel/MainCarrousel';
import Courses from './pages/Courses/Courses';
import History from './pages/History/History';
import Records from './pages/Records/Records';
import Space from './pages/Space/Space';
import Store from './pages/Store/Store';
import Teachers from './pages/Teachers/Teachers';
import Footer from './ui/Footer/Footer';
import Navbar from './ui/Navbar/Navbar';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <MainCarrousel />
        <Courses />
        <Teachers />
        <History />
        <Biography />
        <Records />
        <Store />
        <Space />
        <Footer />
        <WhatsAppButton />
      </BrowserRouter>
    </div>
  );
}

export default App;