import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import AppPreLoad from "./components/AppPreLoad";

const rootElement = document.getElementById("root");
if (!rootElement.hasChildNodes() && 'REACT_APP_PRELOAD' in process.env) {
    render(
      <React.StrictMode>
          <AppPreLoad/>
      </React.StrictMode>, rootElement);
} else {
    hydrate(
      <React.StrictMode>
          <App/>
      </React.StrictMode>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();