import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import audiodream from '../../../assets/images/clients/audio-dream.png';
import avpro from '../../../assets/images/clients/av-pro.png';
import bateraclub from '../../../assets/images/clients/batera.png';
import gsb from '../../../assets/images/clients/gsb.png';
import izzo from '../../../assets/images/clients/izzo.png';
import michael from '../../../assets/images/clients/michael.png';
import pearl from '../../../assets/images/clients/pearl.png';
import pride from '../../../assets/images/clients/pride.png';
import './ClientLogoCarousel.scss';

const logos = [
  { id: 1, src: michael, alt: 'Client 1', link: 'http://michael.com.br/' },
  { id: 2, src: izzo, alt: 'Client 2', link: 'http://izzo.com.br/' },
  { id: 3, src: pride, alt: 'Client 3', link: 'http://pridemusicshop.com.br/' },
  { id: 4, src: pearl, alt: 'Client 4', link: 'http://pearldrum.com/' },
  { id: 5, src: bateraclub, alt: 'Client 5', link: 'http://bateraclube.com.br/' },
  { id: 6, src: gsb, alt: 'Client 6', link: 'http://gsbproaudio.com.br/' },
  { id: 7, src: audiodream, alt: 'Client 7', link: 'https://www.audiodream.com.br' },
  { id: 8, src: avpro, alt: 'Client 8', link: 'http://avpro.com.br/' },
];

const ClientLogoCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="ClientLogoCarousel">
      <p className="title">Parceiros</p>
      <Slider {...settings}>
        {logos.map((logo) => (
          <a
            key={logo.id}
            href={logo.link}
            target="_blank"
            rel="noopener noreferrer"
            className="carousel-item"
          >
            <img src={logo.src} alt={logo.alt} />
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default ClientLogoCarousel;
