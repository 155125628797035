import React from 'react'
import './Header.scss';
import Button from '../../common/Button/Button'
import { links } from "../../../constants/links";

const Header = () => {
  return (
    <section id='home' className='Header'>
      <div className="bg">
        <div className="content">
          <h1 className='desktop'>MIDAS ACADEMY: <br/> UMA ESCOLA COM FILOSOFIA <br/> DE SHOW BUSINESS!
          </h1>
          <h1 className='mobile'>MIDAS ACADEMY: <br/> UMA ESCOLA COM FILOSOFIA <br/> DE SHOW BUSINESS!</h1>
          <p>Trazendo a vivência real do mercado para que você esteja 100% preparado para iniciar ou alavancar a sua carreira.</p>
          <Button href={links.contact.becomeStudent}>QUERO SER ALUNO</Button>
        </div>
      </div>
    </section>
  )
}

export default Header
