import React from 'react';
import './Button.scss';

const Button = ({ onClick, children, active, href, img }) => {
  let ariaLabel = "navigation";
  if (typeof children === "string") {
    ariaLabel = children;
  }
  return (
    <div className='ButtonContainer'>
      <a href={href} target="_blank" rel="noreferrer">
        <button className={`Button  ${active ? "active" : ""}`} onClick={onClick} aria-label={ariaLabel}>
          {img && <img src={img} style={{marginRight: "20px"}} alt={ariaLabel} title={ariaLabel} />}
          {children}
        </button>
      </a>
    </div>
  )
}

export default Button;
