import React, { useState, useEffect } from 'react';
import './MasonryGrid.scss'; // Estilos SCSS

const MasonryGrid =  ({ children, columnsCountBreakPoints }) => {
  const [columns, setColumns] = useState(3); // Número inicial de colunas

  useEffect(() => {
    const updateColumns = () => {
      const screenWidth = window.innerWidth;
      let newColumns = 1;
      for (const breakpoint in columnsCountBreakPoints) {
        if (screenWidth >= parseInt(breakpoint)) {
          newColumns = columnsCountBreakPoints[breakpoint];
        }
      }
      setColumns(newColumns);
    };

    updateColumns(); // Chama a função inicialmente

    window.addEventListener('resize', updateColumns); // Atualiza o número de colunas ao redimensionar a janela

    return () => {
      window.removeEventListener('resize', updateColumns); // Remove o listener ao desmontar o componente
    };
  }, [columnsCountBreakPoints]);

  const columnElements = Array.from({ length: columns }, () => []);

  children.forEach((child, index) => {
    const columnIndex = index % columns;
    columnElements[columnIndex].push(child);
  });

  return (
    <div className="custom-responsive-masonry">
      {columnElements.map((column, index) => (
        <div key={index} className="custom-responsive-masonry-column">
          {column.map((item, itemIndex) => (
            <div key={itemIndex} className="custom-responsive-masonry-item">
              {item}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MasonryGrid;
